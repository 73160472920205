import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import faUserCircle2 from '../../../assets/img/icono.png';
import { Row, Col, Dropdown, Form, Image, Nav, NavDropdown, Navbar, Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../../components/Modals/sessionExpired';
import InactivityModal from '../../../components/Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga4';
import AddOrganizationModal from '../../../components/Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import logo from "../../../assets/img/AVAP_ID_horizontal.png"
import logoBlanco from "../../../assets/img/AVAP_ID_horizonta_blanco.png"

import icono_menu_flecha_abajo from "../../../assets/img/newDashboard/icono_menu_flecha_abajo.png"

import './NewHeaderMenu.css';

import allDataSidebarApis from "../../../configs/routers/new-apis-router";

//redux
import { useDispatch, useSelector } from 'react-redux';
import { updateSendToNameSection } from '../../../redux/slices/KnowDivInScreen';
import config from '../../../configs/appConfig.json'

import sun from '../../../assets/img/ui_icons/sun.svg';
import moon from '../../../assets/img/ui_icons/moon.svg';

//../../Modals/sessionExpired

const NewHeader: FunctionComponent<{}> = () => {

    const history = useHistory();
    const [isLoggedIn, setLogged] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [inactivity, setInactivity] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [projects, setProjects] = useState([]);
    const [nombreOrganizacion, setNameOrg] = useState('');
    const [currentOrganizations, setCurrentOrganizations] = useState(0);
    const [maxOrganizations, setMaxOrganizations] = useState(0);
    const cookies = new Cookies();

    const [usersuscriptor, setusersuscriptor] = useState(true);
    const [developer, setDeveloper] = useState(false);
    const [client, setClient] = useState(false);

    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme === 'dark';
    });

    const dispatch = useDispatch()

    const handleClose = () => {
        setShowModal2(!showModal2);

    }

    const analyticsTags = [
        'G-98LF8608ZM',
    ];


    const handleLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        SessionService.logout();
        history.push('/login');

    }

    const getProjects = async () => {
        try {
            setProjects([]);
            let projects = null;

            console.log('INICIO');

            if (SessionService.ORGANIZATIONS == null) {
                try {
                    //projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
                    projects = []
                    console.log('NULL en ORGANIZATION');
                } catch { projects = [] }
                SessionService.ORGANIZATIONS = projects
                console.log('NULL y erro en ORGANIZATION');
            }
            else {
                console.log('SI ORGANIZATION');
                projects = SessionService.ORGANIZATIONS;
            }
            if (projects.length == 0) {
                console.log('PROJECT LENGTH 0');
                setNameOrg('My Company');
                SessionService.ORGANIZATION_NAME = 'My Company';
                SessionService.ORGANIZATION = -1;
            } else {
                if (projects.result.length == 0) {
                    console.log('PROJECT LENGTH 0b');
                    setNameOrg('My Company');
                    SessionService.ORGANIZATION_NAME = 'My Company';
                    SessionService.ORGANIZATION = -1;
                    console.log('PROJECT LENGTH 0c');
                }
            }
            setCurrentOrganizations(1);
            setMaxOrganizations(1);
            setCurrentOrganizations(projects['result'].length || 0);
            setMaxOrganizations(projects['max']);
            console.log('final');
            setProjects(projects['result']);

            if (projects['result'].length > 0) {
                if (SessionService.ORGANIZATION_NAME == null) {
                    setNameOrg(projects['result'][0]['name']);
                    SessionService.ORGANIZATION_NAME = projects['result'][0]['name'];
                    SessionService.ORGANIZATION = projects['result'][0]['id'];
                } else {
                    setNameOrg(SessionService.ORGANIZATION_NAME);
                }
            } else {
                SessionService.ORGANIZATION_NAME = 'Sin Organización';
                SessionService.ORGANIZATION = -1;
            }

        } catch (error) {
            console.error('ERROR FETCHING PROJECTS ', error);


        }

    }


    useEffect(() => {

        //ReactGA.pageview(window.location.pathname + window.location.search);

        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-98LF8608ZM');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' })
        }//.pageview(window.location.pathname + window.location.search);}


        const user = cookies.get('101Obex');
        if (user) {
            // console.log('User data')
            // console.log(user);
            setDeveloper(SessionService.Developer);
            setusersuscriptor(SessionService.User);
            setClient(SessionService.Client);
            if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
                SessionService.renewSession();
            }
            setLogged(true);
            getProjects();
        }

        // console.log(allDataSidebarApis);

    }, [])

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('theme', newTheme ? 'dark' : 'light');
      };

    // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
    document.addEventListener('inactivityEvent', (e) => {

        e.preventDefault();
        e.stopPropagation();
        setInactivity(true);
    }, false);

    document.addEventListener('organizationsChange', (e) => {

        e.preventDefault();
        e.stopPropagation();
        getProjects();

    }, false);

    //organizationsChange

    // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
    document.addEventListener('sessionExpiredEvent', (e) => {

        if (sessionExpired) return;
        e.preventDefault();
        e.stopPropagation();
        setSessionExpired(true);
    }, false);

    const setName = (orga) => {

        setNameOrg(orga.name);
        SessionService.ORGANIZATION_NAME = orga.name;
        SessionService.ORGANIZATION = orga.id;
        history.push('/dashboard');
        return orga.name;
    }



    const renewSession = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            await SessionService.renewSession();
            setInactivity(false);
        } catch (error) {
            console.error(error);
        }
    }
    const handleModal = () => {

        setShowModal(!showModal);
    };

    const on_Change = (event) => {
        const value = event.target.value;

        // this will return C:\fakepath\somefile.ext
        console.log(value);

        const files = event.target.files;

        //this will return an ARRAY of File object
        console.log(files);


        const input = event.target;

        const reader = new FileReader();
        reader.onload = function () {
            const dataURL = reader.result;
            const output = document.getElementById('output') as HTMLImageElement;
            //document.getElementById('output').setAttribute( 'src',dataURL);
            output.src = dataURL.toString();
            console.log(dataURL)
        };
        reader.readAsDataURL(input.files[files.length - 1]);

        //setImage(files[(files.length)-1].name);

        //setNoUpload(false);

    }

    const getAndSetNameSection = (item: any) => {

        const path = item.links[0] ? item.links[0].path : "none";
        const nameDiv = item.id + "-" + path;
        dispatch(updateSendToNameSection(nameDiv));
        // history.push('/apis/login/Accesos_EN');
        window.open(window.location.origin + "/apis/login/Accesos_EN/" + nameDiv, "_blank")
    };

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);
    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }
    return (


        <Navbar expand="lg" className="avapid-navbar fixed" >
            <Container className="" style={{ position: 'fixed', minWidth: '100%', height:'50px' }}>
                <Navbar.Brand href="/login" style={{}}>
                    <Image className='avapidLogo' src={isDarkMode ? logoBlanco : logo} style={{ marginLeft: '17px' }} height="" width="200px" alt="Logo" />
                </Navbar.Brand>
                <Nav style={{ width: '80%' }}>
                    {/*}
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu1(!menu1)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Products <div hidden={!menu1} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu2(!menu2)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Services <div hidden={!menu2} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{window.open('https://speed-computing-1656.my.site.com/connect/s/','_blank');cleanMenuAll();}} style={{ marginRight:'10px', marginLeft:'10px' }}>AVAP ID <div hidden={!menu3} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu4(!menu4)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Resources<div hidden={!menu4} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu5(!menu5)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Company  <div hidden={!menu5} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                */}
                </Nav>
                {/*<button 
                    style={{ padding:'0px', width:'350px', height:'30px' }}
                    className='custom-button-green textBold'
                    onClick={()=>{history.push('/login')}}
                >
                        Sign in with your AVAP ID
                </button>*/}
                <div className=' pl-1 d-flex align-items-center'> 
                <button onClick={() => { setShowModal2(true) }} style={{ paddingLeft: '5px', paddingRight: '5px', width: '100px', height: '38px' }} className='customButton-green textBold'>Sign out</button>

                    <button
                        onClick={toggleDarkMode}
                        className="workspace-button"
                    >
                        <img style={{ width: '20px' }} src={isDarkMode ? sun : moon} alt="Workspace Menu" />
                    </button>
                    {/*}
                <a className=" link link-header fonsize14 color1Green"  onClick={() => history.push('/login')} style={{ textAlign: 'center', }}>
                  <div style={{ width:'200px' }} className='link link-header fonsize14 color1Green'>
                    Sign out
                  </div>
                </a>
                */}
                </div>

                <Modal style={{ marginTop: '4rem' }} show={showModal2} onHide={handleClose} container={document.getElementById('avapIdWrapper')}> 
                    <Modal.Body className='colorBlack'>Do you want to sign out?</Modal.Body>
                    <Modal.Footer>
                        <button className="btn customButton-green  textBold" onClick={() => history.push('/login')}>
                            Yes
                        </button>

                        <button className="btn customButton-green  textBold" onClick={handleClose}>
                            No
                        </button>

                    </Modal.Footer>
                </Modal>


            </Container>

            {menu1 &&
                <><div style={{
                    width: '80%',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    marginTop: '52px',
                    position: 'absolute',
                    height: '2px',
                    backgroundColor: '#ebebeb'
                }}></div>
                    <div className='fade-in-image' style={{
                        marginTop: '374px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Build Virtual APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }} onClick={() => { history.push('/avap') }}>
                                    AVAP (Advanced Virtual API Programming)
                                </div>
                                <div onClick={() => { history.push('/vsc-extensions') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP API Extensions for Microsoft Visual Code
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }} onClick={() => { history.push('/devstudio') }}>
                                    AVAP Dev Studio
                                </div>



                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Test & Deliver APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick={() => { history.push('/version-control') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP API Version Control
                                </div>
                                <div onClick={() => { history.push('/api-publisher') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP API Publisher
                                </div>

                            </div>


                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Run APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                {/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div>

                                */}
                                {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }} onClick={() => { history.push('/avs') }}>
                                    AVAP Virtual Server
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }} onClick={() => { history.push('/avap') }}>
                                    AVAP Interpreter & Language Server
                                </div>
                            </div>



                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Power Up APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={() => { history.push('/avap-marketplace') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP Marketplace
                                </div>
                                <div onClick={() => { history.push('/avap-connectors') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px',
                                    }}

                                >
                                    AVAP Connectors
                                </div>
                                {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                            </div>
                            {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                        </div>


                    </div></>
            }

            {menu2 &&
                <><div style={{
                    width: '80%',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    marginTop: '52px',
                    position: 'absolute',
                    height: '2px',
                    backgroundColor: '#ebebeb'
                }}></div><div className='fade-in-image' style={{
                    marginTop: '215px',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    position: 'absolute',
                    width: '80%',
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Professional
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => { history.push('/collaborative') }}
                                >
                                    AVAP Collaborative Network
                                </div>
                                <div onClick={() => { history.push('/cloud') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}>
                                    AVAP Cloud Professional
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Business
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick={() => { history.push('/cloud') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP Cloud Business
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                            </div>


                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Corporate
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick={() => { history.push('/cloud') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP Cloud Enterprise
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                            </div>

                        </div>
                    </div></>
            }

            {menu3 &&

                <><div style={{
                    width: '80%',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    marginTop: '52px',
                    position: 'absolute',
                    height: '2px',
                    backgroundColor: '#ebebeb'
                }}></div><div className='fade-in-image' style={{
                    marginTop: '148px',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    position: 'absolute',
                    width: '80%',
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {/*} Partner Ecosystem*/}
                                </div>
                                <div style={{
                                    height: '1px',
                                    display: 'none',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>



                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {/*Find a Partner*/}
                                </div>
                                <div style={{
                                    height: '1px',
                                    display: 'none',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>



                            </div>


                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {/*Become a partner*/}
                                </div>
                                <div style={{
                                    height: '1px',
                                    display: 'none',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                            </div>
                        </div>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {/*Partner Login*/}
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: 'transparent',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer',
                                    marginBottom: '35px'
                                }}>
                                </div>
                            </div>
                        </div>
                    </div></>
            }

            {menu4 &&
                <><div style={{
                    width: '80%',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    marginTop: '52px',
                    position: 'absolute',
                    height: '2px',
                    backgroundColor: '#ebebeb'
                }}></div><div className='fade-in-image' style={{
                    marginTop: '216px',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    position: 'absolute',
                    width: '80%',
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Developer Support
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => { history.push('https://avapcloud.com/default') }}
                                >
                                    AVAP Developer Portal
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer',
                                    marginBottom: '34px'
                                }}
                                    onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank') }}
                                >
                                    AVAP Communities
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Knowledge Center
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US') }}
                                >
                                    AVAP Alexandria
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => { window.open('http://blog.avapframework.com') }}
                                >
                                    AVAP Blog
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                            </div>
                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    API Products
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={() => { history.push('/avap-marketplace') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    AVAP Marketplace
                                </div>
                            </div>
                            {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                        </div>

                    </div></>
            }
            {menu5 &&
                <><div style={{
                    width: '80%',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    marginTop: '52px',
                    position: 'absolute',
                    height: '2px',
                    backgroundColor: '#ebebeb'
                }}></div><div className='fade-in-image' style={{
                    marginTop: '246px',
                    marginLeft: '10%',
                    marginRight: 'auto',
                    position: 'absolute',
                    width: '80%',
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '15px',
                    borderBottomRightRadius: '15px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%',
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Company
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer',
                                }}>
                                </div>

                                <div onClick={() => { history.push('/about') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    About Us
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer',
                                    marginBottom: '34px'
                                }} onClick={() => { window.open('mailto: info@101obex.com', '_self') }}>
                                    Contact Us
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Legal
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    Terms & Conditions AVAP Collaborative Networks
                                </div>
                                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                    Terms & Conditions AVAP Cloud
                                </div>
                                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}>
                                    Privacy Policy
                                </div>
                                {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                            </div>

                        </div>
                    </div></>
            }

        </Navbar>
    );

    {/*}
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout} />
      <InactivityModal show={inactivity && !sessionExpired} renewSession={renewSession} closeSession={handleLogout} />
      <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2 Newheader'} >

        <Row style={{ width: '100%' }}>



          <div className='styleLogo justify-content-center' style={{width:'293px'}}>
            <div className='containerLogo' style={{textAlign:'center'}}>
              <div>
                <img src={logo} alt="Logo" />
              </div>
            </div>
          </div>

          <Col>
            <div className='d-flex' style={{marginLeft:"30px",marginTop:"20px"}}>
              <div>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} >Products</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} >Partners</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} >Resources</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} >Company</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} >Contact Sales</a>
              </div>

            </div>

          </Col>
          <Col className='d-flex align-items-center justify-content-end'>
            <Row className="justify-content-end" style={{  marginRight: "10px" }}>
              <div >
                
                <Nav className='color'>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={
                      <span>
                        API Reference <img src={icono_menu_flecha_abajo} alt="custom arrow" className="custom-dropdown-icon" />
                      </span>
                    }
                    className='color1Green'
                    style={{ color: "#4b7b68" }}
                  >
                    {allDataSidebarApis.menus.map((item, index) => (
                      <NavDropdown.Item onClick={() => getAndSetNameSection(item)} className='text-capitalize custom-dropdown-item'>{item.id}</NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>

              </div>
              <div className='d-flex align-items-center marginLefItemsHeader'>
                <a className=" link link-header fonsize14 color1Green"   onClick={() => window.open(window.location.origin+"/docs/download/download_101cli", "_blank") /*history.push('/docs/download/download_101cli') style={{ textAlign: 'center',  }} >Docs</a>
              </div>
              <div className='d-flex align-items-center marginLefItemsHeader'>
                <a target="_blank" rel="noopener noreferrer " onClick={()=> window.open("https://101obexworkspace.slack.com/", "_blank")} className=" link link-header fonsize14 color1Green" style={{ textAlign: 'center' }}>Support</a>
              </div>
              <div className='d-flex align-items-center'>
                <a className=" link link-header fonsize14 color1Green"  onClick={() => history.push('/login')} style={{ textAlign: 'center', }}>
                  <div className='link link-header fonsize14 color1Green'>
                    Sign Out <span className='fonsize12'>→</span>
                  </div>
                </a>
              </div>
            </Row>
          </Col>


          {isLoggedIn ?
            // <Col md={1} className="user text-right my-auto">
            //   <Dropdown className="mr-1">
            //     <Dropdown.Toggle className="link-user" id="obex-user-menu"><div style={{ height:'30px' }}><Image id='AvatarImageHeader' style={{ height:'30px' }} src={SessionService.imageContent}/></div></Dropdown.Toggle>
            //     <Dropdown.Menu>
            //       { usersuscriptor && <Dropdown.Item onClick={() => {
            //         history.push('/dashboard')}}><h5>Administrator Dashboard</h5></Dropdown.Item>}
            //       { developer && <Dropdown.Item onClick={() => {
            //         SessionService.ORGANIZATION_NAME = null;
            //         history.push('/devdashboard')}}>Developer Dashboard</Dropdown.Item>}
            //       { client && <Dropdown.Item onClick={() => {
            //         SessionService.ORGANIZATION_NAME = null;
            //         history.push('/clientdashboard')}}>Client Dashboard</Dropdown.Item>}
            //       <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            //     </Dropdown.Menu>{' '}
            //   </Dropdown>
            // </Col>
            <></>
            :
            // <Col md={2} className="login text-right my-auto">
            //   <a style={{ height:'47px', width:'200px', marginRight:'-20px', paddingTop:'12px', fontSize:'16px' }} href="/login" className="link-login">Log into Developer Portal</a>
            // </Col>
            <></>
          }





        </Row>
      </Row>

      {showModal && <AddOrganizationModal show={showModal} handleClose={handleModal} />}


    </Fragment>
    */}
    //  );
}

export default NewHeader;