import React, { useState, useEffect, useRef } from "react";
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import ReactGA from 'react-ga4'
const items = [
    {
        "quarter": "Q1 2025",
        "products": [
            {
                "platform": "Infrastructure",
                "title": "Project and Organization Migration",
                "description": "Enable live migration of projects and organizations by location for greater infrastructure flexibility. Designed for speed and value, it enhances service offerings, ensuring a smoother experience and more competitive solutions for customers.",
                "tags": ["Infrastructure", "Optimization"],
                "icon": "icon-infrastructure.svg",
                "type": "yellow",
                "date": "Q1 2025"
            },
            {
                "platform": "Developer Portals",
                "title": "Security and Developer Portal Classification",
                "description": "Outline security protocols and categorization of Developer Portals into public, private, and semi-public for controlled access and management.",
                "tags": ["Security", "Dev Portals"],
                "icon": "icon-dev-portals.svg",
                "type": "yellow",
                "date": "Q1 2025"
            },
            {
                "platform": "Communications",
                "title": "Advanced RPC and WebSockets Optimization",
                "description": "Enhancing RPC and WebSockets for faster, more efficient, and reliable service communication.",
                "tags": ["Networking", "Optimization"],
                "icon": "icon-networking.svg",
                "type": "green",
                "date": "Q1 2025"
            },
            {
                "platform": "Brunix AI",
                "title": "Brunix Copilot: OpenAI Integration",
                "description": "Comprehensive integration of OpenAI as Brunix Copilot to enhance content generation, from product and business materials to supporting content across various domains.",
                "tags": ["AI", "Automation"],
                "icon": "icon-ai-brunix.svg",
                "type": "red",
                "date": "Q1 2025"
            },
            {
                "platform": "Marketplace",
                "title": "Legacy API Product Integration into the Marketplace",
                "description": "Seamless and organized integration of legacy API products into the AVAP Marketplace, ensuring compatibility, easy access, and enhanced user experience, while maintaining functionality and performance across all systems.",
                "tags": ["API Products", "Marketplace"],
                "icon": "icon-marketplace.svg",
                "type": "red",
                "date": "Q1 2025"
            },
            {
                "platform": "Databases",
                "title": "Integration of Multiple Databases",
                "description": "Development of connectors to streamline the integration and management of multiple databases.",
                "tags": ["DB", "Optimization"],
                "icon": "icon-db.svg",
                "type": "yellow",
                "date": "Q1 2025"
            },
            {
                "platform": "Administration",
                "title": "Development of Administrative and Billing Components",
                "description": "Design and development of administrative, billing, and platform management components to ensure seamless operations and control.",
                "tags": ["Administration", "Billing"],
                "icon": "icon-admin.svg",
                "type": "green",
                "date": "Q1 2025"
            },
            {
                "platform": "AVAP",
                "title": "AVAP Language Server Segmentation",
                "description": "The AVAP language server is divided into two core modules: the kernel and implementation. This architecture forms the foundation for the development of the Open Source project, PLATON.",
                "tags": ["AVAP", "Open Source"],
                "icon": "icon-avap.svg",
                "type": "red",
                "date": "Q1 2025"
            }
        ]
    },
    {
        "quarter": "Q2 2025",
        "products": [
            {
                "platform": "PLATON",
                "title": "Official Launch of the Open Source PLATON Project",
                "description": "Introducing the official release of the Open Source PLATON project, a significant step forward in open-source innovation and collaboration.",
                "tags": ["Open Source", "PLATON"],
                "icon": "icon-platon.svg",
                "type": "yellow",
                "date": "Q2 2025"
            },
            {
                "platform": "PLATON",
                "title": "Migration of PLATON to Node.js",
                "description": "Transitioning the PLATON architecture to Node.js to enhance performance, scalability, and efficiency.",
                "tags": ["Node.js", "Migration"],
                "icon": "icon-node.svg",
                "type": "yellow",
                "date": "Q2 2025"
            }
        ]
    }
]


const CloudProduct = () => {
    const [filteredQuarters, setFilteredQuarters] = useState(items);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);

    // Obtener todas las plataformas
    const allPlatforms = items.reduce((acc, q) => {
        const quarterPlatforms = q.products.map(p => p.platform);
        return acc.concat(quarterPlatforms);
    }, []);
    const uniquePlatforms = Array.from(new Set(allPlatforms));

    // Obtener quarters únicos
    const uniqueQuarters = items.map(q => q.quarter);

    const [showPlatformDropdown, setShowPlatformDropdown] = useState(false);
    const platformRef = useRef(null);
    const [showQuarterDropdown, setShowQuarterDropdown] = useState(false);
    const quarterRef = useRef(null);

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Roadmap Page' });
            }

    }, [])

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);   

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (platformRef.current && !platformRef.current.contains(event.target)) {
                setShowPlatformDropdown(false);
            }
            if (quarterRef.current && !quarterRef.current.contains(event.target)) {
                setShowQuarterDropdown(false);
            }
        };

        document.body.classList.add('dark-bg');
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.body.classList.remove('dark-bg');
        };
    }, []);

    const handleSearch = () => {
        let result = [...items];

        if (selectedQuarter) {
            result = result.filter(q => q.quarter === selectedQuarter);
        }

        if (selectedPlatform) {
            result = result.map(q => ({
                ...q,
                products: q.products.filter(p => p.platform === selectedPlatform)
            })).filter(q => q.products.length > 0);
        }

        setFilteredQuarters(result);
    }



    return (
        <>
            <FlNav />
            <div id="roadmap">
                <div className="w-100 roadmapHeader">
                    <div className="fl-content">
                        <div className="fl-title">Product Roadmap</div>
                        <div className="fl-subtitle">What are the latest developments at AVAP?</div>
                        <div className="fl-text">Stay up to date with what we're working on, planned releases and our newest features.</div>
                    </div>

                    <div className="search">
                        <div className="instructions">
                        Select up to two options:   
                        </div>
                        <div className="fl-selectsWrapper">
                            {/* Select de producto */}
                            <div className="fl-customSelectWrapper productselect" ref={platformRef}>
                                <div 
                                    className="fl-customSelect" 
                                    onClick={() => setShowPlatformDropdown(!showPlatformDropdown)}
                                >
                                    <span>{selectedPlatform ? selectedPlatform : "Select a product"}</span>
                                    <div className="fl-arrow"></div>
                                </div>

                                {showPlatformDropdown && (
                                    <div className="fl-customOptions">
                                        {uniquePlatforms.map((plat, i) => (
                                            <div 
                                                key={i} 
                                                className="fl-customOption" 
                                                onClick={() => {
                                                    setSelectedPlatform(plat);
                                                    setShowPlatformDropdown(false);
                                                }}
                                            >
                                                {plat}
                                            </div>
                                        ))}
                                        <div 
                                            className="fl-customOption" 
                                            onClick={() => {
                                                setSelectedPlatform(null);
                                                setShowPlatformDropdown(false);
                                            }}
                                        >
                                                Clean filters
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Select de Quarter */}
                            <div className="fl-customSelectWrapper " ref={quarterRef}>
                                <div 
                                    className="fl-customSelect" 
                                    onClick={() => setShowQuarterDropdown(!showQuarterDropdown)}
                                >
                                    <span>{selectedQuarter ? selectedQuarter : "Select a quarter"}</span>
                                    <div className="fl-arrow"></div>
                                </div>

                                {showQuarterDropdown && (
                                    <div className="fl-customOptions">
                                        {uniqueQuarters.map((q, i) => (
                                            <div 
                                                key={i} 
                                                className="fl-customOption" 
                                                onClick={() => {
                                                    setSelectedQuarter(q);
                                                    setShowQuarterDropdown(false);
                                                }}
                                            >
                                                {q}
                                            </div>
                                        ))}
                                        <div 
                                            className="fl-customOption" 
                                            onClick={() => {
                                                setSelectedQuarter(null);
                                                setShowQuarterDropdown(false);
                                            }}
                                        >
                                            Clean filters
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Botón de búsqueda */}
                            <button className="fl-searchButton" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="timeline mt-5">
                                {filteredQuarters.map((quarterData, qIndex) => {
                                    
                                    const year = quarterData.products.length > 0 
                                        ? quarterData.products[0].date.substring(0,4) 
                                        : 'N/A';

                                    return (
                                        <React.Fragment key={qIndex}>
                                           
                                            <div className="quarterTitle">
                                                {quarterData.quarter}
                                            </div>
                                            {quarterData.products.map((item, index) => (
                                                <div key={index} className="fl-timelineWrapper">
                                                    {/* Fecha */}
                                                    <div className="date">
                                                        <div className="dateWrapper">
                                                            {item.date}
                                                        </div>
                                                        <div className="fl-border"></div>
                                                    </div>

                                                    {/* Card */}
                                                    <div className="fl-timelineCard">
                                                        <div className="fl-cardContent">
                                                            {/* Producto */}
                                                            <div className={`product ${item.type}`}>{item.platform}</div>

                                                            {/* Título */}
                                                            <div className="fl-subtitle">{item.title}</div>

                                                            {/* Descripción */}
                                                            <div className="fl-text">{item.description}</div>

                                                            {/* Etiquetas */}
                                                            <div className="labels">
                                                                {item.tags.map((tag, idx) => (
                                                                    <div key={idx} className="label">{tag}</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FlFooter />
        </>
    );
};

export default CloudProduct;
