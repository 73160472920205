import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import ReactGA from 'react-ga4'



import fondo from '../../assets/img/landingPage/fondoApps.png';

import icon_expo from '../../assets/img/landingPage/collab/CN_01_scalability.svg'
import icon_dyn from '../../assets/img/landingPage/collab/CN_02_openAccess.svg'
import icon_free from '../../assets/img/landingPage/collab/CN_03_security.svg'

import features_1 from '../../assets/img/landingPage/collab/collaborative-01.png'
import features_2 from '../../assets/img/landingPage/collab/collaborative-02.png'
import features_3 from '../../assets/img/landingPage/collab/collaborative-03.png'




import logo from '../../assets/img/logos/AVAP_Collaborative_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const PublisherProduct = () => {

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Collaborativev Page' });
            }

    }, [])

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Collaborativev Page' });
            }

    }, [])

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);


        // clean favicon 
        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Unlock limitless API development potential</h1>
                    <p className="fl-subtitle">
                        The first free peer-to-peer platform for <br />professionals
                    </p>
                    <a className="glowBtn-dark " href="https://avapcollaborativenetwork.com/" target="_blank"> <span className="btnInner">   Visit AVAP Collaborative Network</span></a>
                    <a className="glowBtn-dark " href="https://avapcollaborativenetwork.com/" target="_blank"> <span className="btnInner">   Visit AVAP Collaborative Network</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                                Free and International API Development Network
                            </p>
                            <p className="fl-text">
                                Cutting-edge peer-to-peer platform designed for seamless API development and deployment.
                                It empowers developers by providing exponential growth capacity,
                                leveraging AVAP Virtual Server (AVS) and AVAP I&LS for efficient API management.
                                The network intelligently routes API requests to the most efficient server based on response time,
                                with distributed processing threads across a growing network of peers.
                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <img src={icon_expo} alt="" />
                                <p>Scalability</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Open Access</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <img src={icon_free} alt="" />
                                <p>Robust Security</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Exponential Growth Capacity</h3>
                            <p className="fl-text">
                                The more active peers join the network, the more the server capacity scales, ensuring that the system can handle increasing traffic without compromising performance. This dynamic scalability provides an unparalleled advantage for developers, allowing them to deploy applications at scale while leveraging the network’s expanding resources.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Intelligent Routing & Dynamic Thread Distribution:</h3>
                            <p className="fl-text">
                                It optimizes the processing of API requests by dynamically selecting the best AVS to handle each request. This process is based on multiple parameters, including server load, proximity, and processing power. Additionally, dynamic thread distribution ensures that processing threads are effectively distributed across active peers, optimizing resource usage and delivering performance for better efficiency and faster response times.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">High-Level Security</h3>
                            <p className="fl-text">
                                We incorporate advanced security protocols to safeguard the integrity of data and the privacy of users. End-to-end encryption ensures that sensitive information remains protected during data transmission, while stringent access control mechanisms manage who can interact with specific APIs.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Cost-Efficient API Development
                                </p>
                                <p className="fl-text">
                                    Publish and manage APIs without additional cloud service expenses. <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Agility in Deployment & Scaling
                                </p>
                                <p className="fl-text">
                                    Leverage shared resources for fast, scalable API implementation and growth.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Collaborative Learning & Innovation
                                </p>
                                <p className="fl-text">
                                    Access community-driven updates, documentation, and tools to enhance professional development.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default PublisherProduct;
