import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/cloudDashboard.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/cli/CLI-01.png'
import features_2 from '../../assets/img/landingPage/cli/CLI-02.png'
import features_3 from '../../assets/img/landingPage/cli/CLI-03.png'


import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP_CLI-negativo.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import ReactGA from 'react-ga4'

const PublisherProduct = () => {

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Cli Page' });
            }

    }, [])

    
  useEffect(() => {
    // favicon 
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = Favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // clean favicon 
    return () => {
      document.head.removeChild(link);
    };
  }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Optimizing Command-Line Operations</h1>
                    <p className="fl-subtitle">
                        Efficient, Streamlined, and Customizable API Management <br /> <br /> &nbsp;
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">Start with AVAP Cloud</span></a>
                </section>


                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fl-subtitle-xs mb-3">
                                Command-Line Interface for seamless interaction with AVAP Framework
                            </p>
                            <p className="fl-text">
                                AVAP CLI simplifies the management, automation, and deployment of APIs via the command line. 
                                This tool provides developers with a way to interact directly with AVAP Framework, offering 
                                an efficient and scalable solution for API tasks. With AVAP CLI, developers can streamline 
                                their workflows, making it easier to integrate, deploy, and monitor APIs within their projects 
                                while maintaining full control over their environment.
                            </p>
                        </div>
                    </div>

                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>



                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Key Features</div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                           <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> 
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Command-Line API Management</h3>
                            <p className="fl-text">
                                Control every aspect of your API lifecycle from the command line, from creating APIs to 
                                deploying them across different environments. It simplifies configuration, automation, 
                                and API monitoring tasks.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Automated Deployment and Version Control</h3>
                            <p className="fl-text">
                                Automate deployment processes, ensuring that each API version is deployed consistently 
                                and error-free. AVAP CLI integrates smoothly with version control systems to track and 
                                manage changes across development, staging, and production environments. This reduces 
                                human error and optimizes the deployment cycle, resulting in faster, more reliable releases.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Real-Time Monitoring and Logging</h3>
                            <p className="fl-text">
                                AVAP CLI provides real-time monitoring and detailed logging for all commands executed 
                                through the platform. Developers can quickly access logs for troubleshooting and gain 
                                visibility into the status of their APIs.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Efficient Automation & Control
                                </p>
                                <p className="fl-text">
                                    Maximize productivity by automating key tasks such as deployment and testing, 
                                    allowing for smoother workflows. <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Enhanced Flexibility & Integration
                                </p>
                                <p className="fl-text">
                                    Customize AVAP CLI to meet specific development requirements, and integrate it 
                                    seamlessly with existing development tools or CI/CD pipelines.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Streamlined Deployment
                                </p>
                                <p className="fl-text">
                                    Ensure quick and efficient deployment of APIs across multiple environments with 
                                    minimal manual effort and human error. <br />
                                    &nbsp; <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default PublisherProduct;
