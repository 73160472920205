import React, { useEffect } from 'react';

//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import ReactGA from 'react-ga4'


import fondo from '../../assets/img/landingPage/fondoApps.png';


import features_1 from '../../assets/img/landingPage/avap/AVAP-01.png'
import features_2 from '../../assets/img/landingPage/avap/Virtualidad.png'
import features_3 from '../../assets/img/landingPage/avap/IA.png'

import logo from '../../assets/img/logos/AVAP_Lenguaje_white2.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import icon_free from '../../assets/img/landingPage/avap/avap_01_virtuality.svg'
import icon_expo from '../../assets/img/landingPage/avap/avap_02_ai.svg'
import icon_dyn from  '../../assets/img/landingPage/avap/avap_03_smarter.svg'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import tablet from '../../assets/img/landingPage/tabletApi.png'
import frameTablet from '../../assets/img/landingPage/iPad_16.9.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const AvapProduct = () => {

    
  useEffect(() => {
    // favicon 
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = Favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // clean favicon 
    return () => {
      document.head.removeChild(link);
    };
  }, []); 

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework AVAP Page' });
            }

    }, [])


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Advanced Virtual API Programming</h1>
                    <p className="fl-subtitle">
                        AVAP is the next-generation programming language <br /> for API development
                    </p>
                    <a className="glowBtn" href="https://avapcloud.com/business_en" target='_blank'>
                        <span className="btnInner">   AVAP Cloud Start for Free</span>
                    </a>

                </section>

                {/* Powerful API Platform */}
                <section className="text-center py-5 container">
                    <div className="row">
                        <div className="col">
                            {/* <h2 className="fl-subtitle">¿AVAP Virtual API Execution?</h2> */}

                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-4"
                            /> */}
                            <h2 className="fl-subtitle">Unlock the potential of virtual APIs</h2>
                            <h3 className="fl-subtitle-xs">AVAP combines virtuality with artificial intelligence to transform how APIs are developed and deployed</h3>
                            <p className="fl-text">
                                Its virtual nature enables continuous code evolution, offering unmatched scalability and cost efficiency. By shifting the focus from technical execution to strategic problem-solving, AVAP empowers developers to define their goals and focus on the outcome while it handles the implementation—resulting in a smarter, faster, and more impactful era of API innovation.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Virtuality</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Artificial Intelligence</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Smarter and Faster</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div> */}

                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Virtuality and artificial intelligence <br /> will be in the core</div>
                        </div>
                    </div>
                </section>


                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">High Level Programming Language</h3>
                            <p className="fl-text">
                                AVAP's high-level programming language simplifies API creation by handling commands and native data structures. It lets developers focus on functionality, speeding up development. This feature streamlines API management and reduces technical complexity.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Virtual</h3>
                            <p className="fl-text">
                                Our virtual feature enables dynamic API creation and management through real-time configurations, separating functionality from technical specifications. By handling technical aspects in the cloud, APIs evolve without code changes, recompilation, or redeployment.

                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>

                    </div>
                </section>


                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">AI at the Core</h3>
                            <p className="fl-text">
                                AI in AVAP predicts solutions and automates processes, allowing developers to focus on defining problems while AVAP handles the technical execution. This results in improved efficiency, accuracy, and scalability throughout the API development lifecycle.

                            </p>
                        </div>
                    </div>
                </section>


                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col  mb-5">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Efficiency and Productivity
                                </p>
                                <p className="fl-text">
                                    AVAP automates processes and predicts solutions, allowing developers to focus on high-level problem-solving rather than technical execution, speeding up the development process.

                                    <br />
                                    &nbsp;
                                    <br />&nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Scalability and Flexibility
                                </p>
                                <p className="fl-text">
                                    With its virtual nature, AVAP adapts to different environments and evolves without requiring code modifications, ensuring seamless scalability and adaptability to changing demands.
                                    <br />&nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Cost-Effectiveness
                                </p>
                                <p className="fl-text">
                                    AVAP’s ability to streamline development and reduce manual intervention minimizes resource consumption, resulting in lower operational costs and more efficient use of resources.

                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="container py-5">
                    <div className="row mb-5">

                        <div className="col-md-6 ">
                            <div className="ytWrapper2">
                                <img src={frameTablet} alt="" className="frameTablet" />
                                <iframe className='fl-video-container fl-video-mobile' src="https://www.youtube.com/embed/LlVcKzcM3uc?si=RbgB1V_dJPcusm5y&amp;controls=0&rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                            </div>
                            {/*  <p className="mt-3">
                                <a href="https://www.youtube.com/watch?v=LlVcKzcM3uc" className="text-primary" target='_blank'>Watch video →</a>
                            </p> */}
                        </div>
                        <div className="col-md-3">
                            {/* <h2 className="fw-bold">Key Benefits</h2> */}
                            <h3 className="fw-bold mb-5">Getting Started</h3>
                            <ul className="startedList">
                                <li><a href="http://avapid.com" target='_blank'><img src={user} className="mr-2" alt="" />Create an AVAP ID</a></li>
                                <li>
                                    <a href="https://developer.avapframework.com/wiki/over_view12-GET STARTED_Downloads/0" target='_blank'>
                                        <img src={download} className="mr-2" alt="" />Download AVAP API Extensions at MSVC
                                    </a>

                                </li>
                            </ul>
                            {/* <a className="glowBtn-dark mt-0 mx-0" href="#">
                                <span className="btnInner">  Developer Portal →</span>
                            </a> */}
                        </div>
                        <div className="col-md-3">
                            {/* <h2 className="fw-bold">Key Benefits</h2> */}
                            <h3 className="fw-bold mb-5">Resources</h3>
                            <ul className="startedList">
                                <li>
                                    <a href="https://developer.avapframework.com" target='_blank'>
                                        <img src={user} className="mr-2" alt="" />Developer Portal
                                    </a>

                                </li>
                                <li>
                                    <a href="https://developer.avapframework.com/wiki/over_view12-GET STARTED_Downloads/0" target='_blank'>
                                        <img src={download} className="mr-2" alt="" />Getting Started Guide
                                    </a>

                                </li>
                            </ul>

                        </div>
                    </div>
                </section>


                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom opacity-5" />

                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default AvapProduct;
