import React, { useState } from "react";
import logos from "../../../assets/img/landingPage/LogosFamilia_pie.svg";

import icn_mail from "../../../assets/img/landingPage/icono_mail.svg";
import icn_fb from "../../../assets/img/landingPage/icono_Socialmedia_fb.svg";
import icn_in from "../../../assets/img/landingPage/icono_Socialmedia_In.svg";
import icn_r from "../../../assets/img/landingPage/icono_Socialmedia_R.svg";
import icn_x from "../../../assets/img/landingPage/icono_Socialmedia_X.svg";
import icn_yt from "../../../assets/img/landingPage/icono_Socialmedia_YT.svg";
import icn_location from "../../../assets/img/landingPage/icono_ubicacion.svg";

import footer_img from "../../../assets/img/landingPage/footerGradient.png";
import ObexRequestHandler from "../../../handlers/ObexRequestsHandler";



const Footer = () => {

    function validateEmail(email) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
      }

    const [eMail, setEmail] = useState('');
    const [error, setError] = useState(false);

    const handleChangeEmail = (e: React.SetStateAction<string>) => {
    setEmail(e);
    setError(false);
    console.log(e);
    };
    
    return (
        <footer className="fl-footer">
            {/* Primera fila */}
            <div className="fl-footer-row d-flex justify-content-between">
                <p className="fl-footer-logos">
                    <img src={logos} alt="Logos Familia" />
                </p>

                <div className="newsletter-container">
                    <input 
                    style={{ color: !error ? '' : 'red', borderColor: !error ? '' : 'red' }}
                    id='neswletter-mail' 
                    
                    value={eMail||''}
                    onChange={(e)=>{
                        handleChangeEmail(e.target.value);
                    }}
                    type="email" placeholder="Enter your email" />
                    <button
                    onClick={async()=>
                        {if (validateEmail(eMail)){
                            try{
                                const result = await ObexRequestHandler.post('/feedback', {
                                    email: eMail,
                                    subject: 'Suscribe to Newsletter',
                                    message: 'Suscribe to Newsletter'
                                    }
                                );
                                const { success } = result;
                                if (success){
                                    setEmail('');
                                } else{
                                    setError(true)
                                }
                            } catch {
                                setError(true);
                            }
                        } else {
                            setError(true);
                        }
                    }
                    }
                    >Suscribe to Newsletter</button>
                </div>
            </div>

            {/* Segunda fila */}
            <div className="fl-footer-row grid">
                <div>
                    <h4>Products</h4>
                    <ul>
                        <li><a href="/avap">AVAP (Advance Virtual API Programming)</a></li>
                        <li><a href="/devstudio">AVAP Dev Studio</a></li>
                        <li><a href="/devstudio-mvsc">AVAP Dev Studio API Extensions for MVSC</a></li>
                        <li><a href="/gateway">AVAP Gateway Advanced</a></li>
                        <li><a href="/virtual-server">AVAP Virtual Server</a></li>
                        <li><a href="/interpreter">AVAP Interpreter & Language Server</a></li>
                        <li><a href="/commander">AVAP Commander</a></li>
                        <li><a href="/mesh-manager">AVAP Mesh Manager</a></li>
                        <li><a href="/source-control">AVAP Source Control</a></li>
                        <li><a href="/release-manager">AVAP Release Manager</a></li>
                        <li><a href="/api-publisher">AVAP API Publisher</a></li>
                        <li><a href="/connectors">AVAP Connectors</a></li>
                        <li><a href="/vision">AVAP Vision</a></li>
                        <li><a href="/cloud">AVAP Cloud</a></li>
                        <li><a href="/ai-brunix">AI Brunix</a></li>
                    </ul>
                </div>
                <div>
                    <h4>Open Source</h4>
                    <ul>
                        <li><a href="/platon">PLATON</a></li>
                    </ul>

                    <h4>Developer Support</h4>
                    <ul>
                        <li><a href="/default" target="_blank" rel="noopener noreferrer">AVAP Developer Portal</a></li>
                        <li><a href="https://speed-computing-1656.my.site.com/communities/s/" target="_blank" rel="noopener noreferrer">AVAP Communities</a></li>
                    </ul>

                    <h4>Platform Services Management</h4>
                    <ul>
                        <li><a href="/cli">AVAP CLI</a></li>
                        <li><a href="/shell">AVAP Shell</a></li>
                    </ul>

                    <h4>Newest Features</h4>
                    <ul>
                        <li><a href="/roadmap">Product Roadmap</a></li>
                        <li><a href="/downloads">Download Center</a></li>
                    </ul>
                </div>
                <div>
                    <h4>Knowledge Center</h4>
                    <ul>
                        <li><a href="https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US" target="_blank" rel="noopener noreferrer">AVAP Alexandria</a></li>
                        <li><a href="https://speed-computing-1656.my.site.com/blog/s/" target="_blank" rel="noopener noreferrer">AVAP Blog</a></li>
                        <li><a href="/avap-marketplace">AVAP Marketplace</a></li>
                    </ul>

                    <h4 className="mt-40">Education</h4>
                    <ul>
                        <li><a href="/collaborative">AVAP Collaborative Network</a></li>
                    </ul>
                </div>
                <div>
                    <h4>Company</h4>
                    <ul>
                        <li><a href="/about">About us</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                    </ul>

                    <h4 className="mt-40">Partners</h4>
                    <ul>
                        <li><a href="/connect">AVAP Connect</a></li>
                    </ul>
                </div>
                <div>
                    <h4>Legal</h4>
                    <ul>
                        <li><a href="https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US" target="_blank">T&C AVAP Collaborative Network</a></li>
                        <li><a href="https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-t[…]nditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US" target="_blank">T&C AVAP Cloud</a></li>
                        <li><a href="https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US" target="_blank">Privacy Policy</a></li>
                    </ul>

                    <h4 className="mt-40">Getting Started</h4>
                    <ul>
                        <li><a href="https://avapid.com/" target="_blank">Create your AVAP ID</a></li>
                        <li><a href="https://avapcloud.com/business_en" target="_blank">Start for Free AVAP Cloud</a></li>
                        <li><a href="https://developer.avapframework.com" target="_blank">Visit our Developer Portal</a></li>
                    </ul>
                </div>

            </div>

            {/* Tercera fila */}
            <div className="fl-footer-row flex fl-border-bottom">
                <div>
                    <p>Corporate head office</p>
                    <p>1010BEX, CORP 440 N Wolfe RD Sunnyvale 94085, CA</p>
                </div>
                <div>
                    <p><img src={icn_mail} alt="Correo Electrónico" /> sales@1010bex.com</p>
                    <p className="fl-footer-socials">
                        <a href="https://www.linkedin.com/company/avap-framework" target="_blank"><img src={icn_in} alt="LinkedIn" /></a>
                        <a href="https://www.youtube.com/@AVAPFramework" target="_blank"><img src={icn_yt} alt="YouTube" /></a>
                        <a href="https://x.com/AvapFramework" target="_blank"><img src={icn_x} alt="X (Twitter)" /></a>
                        <a href="https://www.facebook.com/AVAPFramework" target="_blank"><img src={icn_fb} alt="Facebook" /></a>

                        {/* <a href="https://www.reddit.com/user/AVAP_Framework/" target="_blank"><img src={icn_fb} alt="Facebook"  /></a> */}
                    </p>
                </div>
            </div>

            {/* Cuarta fila */}
            <div className="fl-footer-row center fl-border-top">
                <p>&copy; 2024 1010BEX, CORP. All rights reserved</p>
            </div>

            <img src={footer_img} alt="" className="footer-img" />
        </footer>
    );
};

export default Footer;
