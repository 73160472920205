import React, { useEffect } from 'react';
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import bg_pricing from '../../assets/img/landingPage/fondo.png'
import ReactGA from 'react-ga4'

import icn_cli from '../../assets/img/logos/icono_Cli.svg'
import icn_interpreter from '../../assets/img/logos/icon_avap.svg'
import icn_api_extensions from '../../assets/img/logos/icono_DS.svg'
import icn_dev_studio from '../../assets/img/logos/icono_DS.svg'
import icn_virtual_server from '../../assets/img/logos/icono_VS.svg'
import icn_gateway_advanced from '../../assets/img/logos/icono_Gateway.svg'

import icn_link from '../../assets/img/ui_icons/linkChain.svg'
import icn_windows from '../../assets/img/ui_icons/windows.svg'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const BASE_URL = 'https://static.avapframework.com/static_images/downloads/staging';

// Datos de las tarjetas
const downloadCards = [
    {
        "title": "AVAP CLI (Command Line Interface)",
        "icon": icn_cli,
        "description": "AVAP CLI is a powerful tool designed to streamline API development and management. It offers developers a seamless way to interact with AVAP Framework, automate tasks, deploy APIs, and manage workflows efficiently.",
        "platforms": {
            "Windows": {
                "download_link": `${BASE_URL}/windows/windows-avapcli.zip`
            },
            "MacOs_intel": {
                "download_link": `${BASE_URL}/macos_intel/macos_intel-avapcli.zip`
            },
            "MacOs_arm64": {
                "download_link": `${BASE_URL}/macos_arm64/macos_arm64-avapcli.zip`
            }, 
            "Linux": {
                "download_link": `${BASE_URL}/linux/linux-avapcli.zip`
            }
        }
    },
    {
        "title": "AVAP Interpreter & Language Server",
        "icon": icn_interpreter,
        "description": "AVAP Interpreter executes instructions written in an AVAP Language Server without the need to compile the source code into machine code beforehand.",
        "platforms": {
            "Windows": {
                "download_link": `${BASE_URL}/windows/windows-AVAP.zip`
            },
            "MacOs_intel": {
                "download_link": `${BASE_URL}/macos_intel/macos_intel-AVAP.zip`
            },
            "MacOs_arm64": {
                "download_link": `${BASE_URL}/macos_arm64/macos_arm64-AVAP.zip`
            }, 
            "Linux": {
                "download_link": `${BASE_URL}/linux/linux-AVAP.zip`
            }
        }
    },
    {
        "title": "AVAP API Extensions",
        "icon": icn_api_extensions,
        "description": "AVAP language and development tools are available and can be installed directly from the Microsoft Visual Code Marketplace.",
        "link": {
            "Microsoft Visual Code Marketplace": {
                "download_link": "https://marketplace.visualstudio.com/search?term=avap&target=VSCode&category=All%20categories&sortBy=Relevance",
                "icon": icn_windows
            }
        }
    },
    {
        "title": "AVAP Dev Studio",
        "icon": icn_dev_studio,
        "description": "AVAP own IDE software application that provides full AVAP technology to code, interpret, control versions, manage projects and publish APIs. Take full advantage of AVAP Framework.",
        "status": "Coming Soon"
    },
    {
        "title": "AVAP Virtual Server",
        "icon": icn_virtual_server,
        "description": "Take control of the complete lifecycle of your APIs with AVAP Virtual Server (AVS) our own API management platform. Your projects will be executed more efficiently thanks to integrated services as API manager and AVAP Gateway Advanced.",
        "platforms": {
            "Windows": {
                "download_link": `${BASE_URL}/windows/windows-AVS.zip`
            },
            "MacOs_intel": {
                "download_link": `${BASE_URL}/macos_intel/macos_intel-AVS.zip`
            },
            "MacOs_arm64": {
                "download_link": `${BASE_URL}/macos_arm64/macos_arm64-AVS.zip`
            }, 
            "Linux": {
                "download_link": `${BASE_URL}/linux/linux-AVS.zip`
            }
        }
    },
    {
        "title": "AVAP Gateway Advanced",
        "icon": icn_gateway_advanced,
        "description": "An AVAP API management service that functions as an intermediary for requests from clients seeking resources from one or more backend services.",
        "platforms": {
            "Kubernetes deployment": {
                "download_link": `${BASE_URL}/deployment.yaml`
            }
        }
    }
];

interface Platform {
    download_link: string;
}

interface Link {
    download_link: string;
    icon: string;
}



const DownloadCard = ({ title, icon, description, platforms, link, status }: {
    title: string;
    icon: string;
    description: string;
    platforms?: Record<string, Platform>;
    link?: Record<string, Link>;
    status?: string;
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedPlatform, setSelectedPlatform] = React.useState<string>('');
    const [downloadLink, setDownloadLink] = React.useState<string>('');
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    // Seleccionar el primer item por defecto
    React.useEffect(() => {
        if (platforms) {
            const firstPlatform = Object.keys(platforms)[0];
            setSelectedPlatform(firstPlatform);
            setDownloadLink(platforms[firstPlatform].download_link);
        }
    }, [platforms]);

    // Cerrar dropdown al hacer click fuera
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    

    return (
        <div className="col-lg-4 col-md-6 mb-4">
            <div className="card-download h-100">
                <div className="card-download-header">
                    <img src={icon} alt={title} className="card-icon" />
                    <h3>{title}</h3>
                </div>
                <div className="card-download-body">
                    <p>{description}</p>
                </div>
                <div className="card-download-footer">
                    {status ? (
                        <div className="coming-soon-badge">{status}</div>
                    ) : platforms ? (
                        <div className="download-platforms">
                            <div className="platform-selector">
                                <div className="custom-dropdown" ref={dropdownRef}>
                                    <button
                                        className="dropdown-toggle"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <span>{selectedPlatform || 'Select Platform'}</span>
                                    </button>
                                    {isOpen && (
                                        <div className="dropdown-menu">
                                            {Object.entries(platforms).map(([platform, { download_link }]) => (
                                                <div
                                                    key={platform}
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        setSelectedPlatform(platform);
                                                        setDownloadLink(download_link);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {platform}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <a
                                    href={downloadLink}
                                    className="download-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                    ) : link && (
                        <div className="download-platforms">
                            {Object.entries(link).map(([platform, platformData]) => (
                                <a
                                    key={platform}
                                    href={platformData.download_link}
                                    className="link-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>
                                        <img src={platformData.icon} alt={platform} className="link-icon" />
                                        <p> {platform}</p>
                                    </span>
                                    <img src={icn_link} alt={platform} className="link-icon" />
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const Downloads = () => {

    useEffect(() =>{

        if (/*config.url != 'https://avs-primary-pre.101obex.mooo.com' &&*/ true){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework Downloads' });
            }

    }, [])

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);
      
    useEffect(() => {
        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };
    }, []);

    return (
        <>
            <FlNav />

            {/* Contenedor principal */}
            <div id="downloads" className='fl-cloud-product' style={{ backgroundImage: `url(${bg_pricing} )`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>

                {/* Encabezado */}
                <div className="w-100 roadmapHeader text-center mb-5 noGradient">
                    <div className="fl-title">AVAP Download Center</div>
                    <p className="mt-4 " style={{ maxWidth: '1000px', margin: '0 auto' }}>
                        Easily access AVAP Framework's latest products, updates, and extensions across multiple platforms.
                        Stay up to date with the newest software releases to ensure optimal performance and seamless API
                        lifecycle management. Select your product to find the right software package and get started.
                    </p>
                </div>

                <div className="container margin-neg-top">
                    <div className="row">
                        {downloadCards.map((card, index) => (
                            <DownloadCard key={index} {...card} />
                        ))}
                    </div>
                </div>

            </div>

            <FlFooter />
        </>
    );
};

export default Downloads;
